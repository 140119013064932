<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>ช่วยเหลือ</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>

      <ion-card>
        <ion-card-header>
          <ion-card-title>พื้นฐาน</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - <b>"บัญชี"</b> บัญชีตามรายการบัญชีที่คุณมี เช่น "เงิดสด", "บช.กสิกร", "บช.กรุงเทพ", "บัตรเครดิต" เป็นต้น
          <br />
          <br />
          - <b>"หมวดหมู่"</b> คือ หมวดหมู่ของรายรับ และรายจ่าย เช่น "เงินเดือน", "ดอกเบี้ย", "ค่าจ้าง", "ค่าอาหาร", "ค่าโทรศัพท์", "ค่าน้ำ", "ค่าเช่าหอพัก" เป็นต้น หมวดหมู่ยิ่งละเอียด ยิ่งทำให้คุณสามารถดูรายงานย้อนหลังเพื่อวิเคราะห์ข้อมูลทางการเงินของคุณได้ละเอียด
          <br />
          <br />
          - <b>ก่อนเริ่มใช้งาน</b> คุณจะต้องสร้าง "บัญชี" และ "หมวดหมู่" เท่าที่คุณนึกออกคร่าวๆ ไว้ก่อน เพื่อให้คุณสามารถเริ่มบันทึกข้อมูล รายรับ-รายจ่าย ได้. โดยคุณสามารถกลับมาสร้างบัญชี และหมวดหมู่ เพิ่มเติมในอนาคตได้เสมอ
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title>หน้า: บันทึกข้อมูล</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - 3 ปุ่มด้านบน ใช้สำหรับบันทึกรายการใหม่ <br />
          -- <ion-text color="danger">Expense</ion-text> รายจ่าย <br />
          -- <ion-text color="primary">Transfer</ion-text> โอน <br />
          -- <ion-text color="success">Income</ion-text> รายรับ
          <br />
          <br />
          - ด้านล่าง จะแสดงรายการที่มีการบันทึกล่าสุด สามารถเอานิ้ว<b>ปัดไปท้างซ้าย</b> เพื่อแสดงปุ่ม <b>"ลบรายการ"</b> และ <b>"แก้ไขรายการ"</b>. และหากรายการมีระบุ Note ไว้ คุณจะสามารถเอานิ้วปัดไปทางขวา เพื่อดู Note ของรายการนั้นได้
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title>หน้า: บัญชี / หมวดหมู่</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - กดปุ่ม "+" ที่มุมขวาบน สำหรับการสร้าง บัญชี/หมวดหมู่ ใหม่
          <br /><br />
          - นิ้วปัด บัญชี/หมวดหมู่ ไปทางซ้าย เพื่อแสดงปุ่ม "แก้ไข" ในกรณีที่ต้องการแก้ไขชื่อ บัญชี/หมวดหมู่
          <br /><br />
          - คุณสามารถคลิกที่ชื่อ บัญชี หรือ หมวดหมู่ เพื่อเข้าไปดูรายการที่เคยบันทึกไว้ของบัญชี/หมวดหมู่ นั้นๆ ได้. ภายในหน้านี้ ที่ด้านบนจะมีให้เลือกช่วงเวลาของรายการ ที่ต้องการดูข้อมูลอยู่
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title>หน้า: รายงานสรุป</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - สำหรับดูกราฟรายงาน ข้อมูลบัญชีที่คุณได้บันทึกรายงานมา เพื่อให้เห็นภาพรวมข้อมูลทางการเงินของคุณ
          <br /><br />
          - ที่มุมขวาบน จะมีให้กดเลือกได้ ว่าจะแสดงกราฟรายงานด้านไหน เราจะมีกราฟพื้นที่ฐานมาให้เบื้องต้นแล้ว (คุณสามารถสร้าง รายงานในมิติที่คุณต้องการ ได้ในเมนูตั้งค่า)
          <br /><br />
          - คุณสามารถคลิก 2 ครั้ง ที่กราฟแท่งในแต่ละเดือน เพื่อขยายเข้าดูข้อมูลเฉพาะเดือนนั้นๆ ได้
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title>การใช้งานในมือถือ</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - เว็บนี้ถูกออกแบบมาให้เหมาะกับการใช้งานผ่านมือถือ เพื่อประสบการณ์การใช้งานที่ดีขึ้น เราแนะนำให้ทำดังนี้
          <br /><br />
          - กรณีที่ใช้ iPhone เปิดเว็บนี้ด้วยแอพ Safari แล้วกดปุ่ม <ion-icon :icon="shareOutline" color="primary"></ion-icon> ที่แถบด้านล่างของ Safari แล้วกดที่
          <b>"เพิ่มไปยังหน้าจอโฮม"</b> แล้วกด "เพิ่ม" ที่มุมขวาบน. เสร็จขั้นตอนนี้ จะมี Icon แอพ LnwSaving ปรากฏอยู่บนหน้า Home screen ของมือถือของคุณ เวลาจะใช้งานให้กดเข้าใช้งานผ่าน Icon นี้แทนการเปิดที่แอพ Safari
          <br /><br />
          - กรณีที่ใช้ Android เปิดเว็บนี้ด้วยแอพ Chrome แล้วกดปุ่มเมนู <ion-icon :icon="ellipsisVerticalOutline" color="primary"></ion-icon> แล้วกดที่
          <b>"เพิ่มไปยังหน้าจอโฮม"</b> . เสร็จขั้นตอนนี้ จะมี Icon แอพ LnwSaving ปรากฏอยู่บนหน้า Home screen ของมือถือของคุณ เวลาจะใช้งานให้กดเข้าใช้งานผ่าน Icon นี้แทนการเปิดที่แอพ Chrome
          <br /><br />
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-title>หากพบปัญหา</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          - ส่งอีเมลแจ้งปัญหา ได้ที่ <a href="mailto:Lnwsaving@Lnw.co.th">Lnwsaving@Lnw.co.th</a>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
/* eslint-disable */
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCardSubtitle,
  IonText,
  IonIcon,
} from "@ionic/vue";
import { shareOutline, ellipsisVerticalOutline } from "ionicons/icons";
export default {
  name: "Form",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonMenuButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonText,
    IonIcon,
  },
  setup() {
    return {
      shareOutline,
      ellipsisVerticalOutline,
    };
  },
};
</script>

<style scoped></style>
